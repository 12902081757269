body {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  background: url(./assets/background.png);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
}

.logo {
  max-width: 30px;
  margin: auto;
  display: block;
  float: right;
}

.main-title {
  text-align: center;
}

p {
  font-size: 17px;
  line-height: 22px;
}

.wrapper {
  padding: 30px 60px;
  background-color: white;
  border-radius: .35rem;
  -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
}

.sidebar .wrapper {
  padding: 30px;
}

@media only screen and (max-width: 680px) {
  .wrapper {
    padding: 30px 20px;
  }
}


.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.loading-spinner {
  color: #2196f3;
  width: 75px !important;
  height: 75px !important;
}

.sk-grid-cube {
  background-color: #2196f3 !important;
}

.orange {
  color: #ffc55e;
}

.red {
  color: #fa6961;
}

.green-bg {
  background-color: #00e676;
}

.orange-bg {
  background-color: #ffc55e;
}

.red-bg {
  background-color: #fa6961;
}

.audit-block {
  font-weight: 300;
  font-size: 1.14em;
  text-align: justify;
}

.clear {
  clear: both;
}

.global-score {
  margin-bottom: 40px;
}


.global-score .place-name {
  line-height: 1.7;
  font-size: 1.8em;
  font-weight: 600;
}

span.address {
  font-size: 1em;
  font-weight: 400;
  color: #767676;
}

.address-block {
  display: flex;
  align-items: center;
}

.address-block > i {
  margin: 15px;
  margin-left: 0px;
}

.global-score .score-block-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-score .score-block {
  display: inline-block;
  text-align: center;
  padding: 10px 25px;
  margin: 10px;
  border-radius: 2rem;
  color: white;
  -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
}

.global-score .score-block .score {
  font-size: 1.7em;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  margin: 0px;
}

.global-score .score-block .score span{
  font-size: 0.6em;
  font-weight: 400;
}

.global-score .score-block-label {
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;
  color: #767676;
  margin-bottom: 0px;
}


.MuiStepLabel-label {
  font-size: 1.2em !important;
}

.shape-wrapper {
  border-radius: 0 0 210% 210% / 30%;
  position: relative;
}

.shape {
  position: absolute;
}

.shape-top {
  top: -.75px;
  right: -.75px;
  left: -.75px;
}

.shape-up {
  margin-top: 0px;
}

.shape-wrapper .content {
  text-align: center;
  width: 100%;
  color: white;
}

.main-wrapper .container{
  z-index: 9999999;
}

@media (min-width: 768px) {
  .pt-md-11,
  .py-md-11 {
      padding-top: 6rem !important;
  }
  .pb-md-15,
  .py-md-15 {
      padding-bottom: 22rem !important;
  }
  .main-wrapper {
      margin-top: -30rem !important;
  }
}

@media (max-width: 767px) {
  .pt-md-11,
  .py-md-11 {
      padding-top: 6rem !important;
      padding-left: 30px;
      padding-right: 30px;
  }
  .pb-md-15,
  .py-md-15 {
      padding-bottom: 16rem !important;
  }
  .main-wrapper {
      margin-top: -25rem !important;
  }
}

.card-header {
  background: none;
  cursor: pointer;
  border: none;
}

.accordion .card-body p {
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 200;
}


.accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-toggle i.fas {
  color: #767676;
  font-size: 1em;
  margin-left: 30px;
}


.audit-block-title {
  margin: 0px;
  margin-bottom: 0px;
  font-size: 1.1em !important;
  font-weight: 500 !important;
}

.audit-block-header-right {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
}

.audit-block-header-right i {
  margin-left: 10px;
}


.audit-block-header-right .progress {
  width: 80%;
  height: 10px;
}

.accordion .card {
  border: none;
  border-radius: 10px;
  padding: 10px 0px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
  box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
  margin: 20px 0px;
}

.alert {
  background: none;
  border: none;
  font-weight: 500;
  color: #212529;
  padding: 0px;
  margin: 20px 0px;
}

.alert-success .alert-heading {
  color: #00e676;
}
.alert-warning .alert-heading {
  color: #ffc55e;
}
.alert-danger .alert-heading {
  color: #fa6961;
}

.alert-link {
  color: #007bff !important;
  font-weight: 600;
  text-decoration: underline;
}

.alert-heading {
  font-weight: 700;
  font-size: 18px;
}

.progress-bar.bg-success {
  background-color: #00e676 !important;
}
.progress-bar.bg-warning {
  background-color: #ffc55e !important;
}
.progress-bar.bg-danger {
  background-color: #fa6961 !important;
}

.progress-label {
  font-size: 14px;
  font-weight: 400;
  color: #515151;
  margin-left: 15px;
}

.progress-label > span{
  font-weight: 600;
}

.blue {
  color: #0176FF;
}

.green {
  color: #00e676;
}

.block-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.block-items .block {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
  min-height: 130px;
}

.block-items .block .icon {
  font-size: 30px;
  margin: 8px;
}

.block-items .block.first {
  border-right: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
}
.block-items .block.second {
  border-bottom: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
}
.block-items .block.third {
  border-top: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
}
.block-items .block.fourth {
  border-left: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
}

.sidebar {
  color: #515151;
}

.sidebar .btn.action:hover, .btn.action-btn:hover {
  background-color: #00D262;
}

.sidebar .btn.action {
  background-color: #00e676;
  color: white;
  border: none;
  font-weight: 600;
  padding: 10px;
  font-size: 1.1em;
}

.sidebar .top-text {
  font-weight: 300;
  text-align: center;
}

.sidebar .new-comp-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #eeeeee;
}

.sidebar .new-comp-block .comp-label {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.sidebar .new-comp-block .comp-label > span {
  font-weight: 300;
}

.sidebar .new-comp-block .comp-value {
  font-size: 1.7em;
  color: #0176FF;
  font-weight: 600;
}

.sidebar .new-comp-block .comp-value > span {
  font-weight: 300;
}



.sidebar .comp-block {
  text-align: center;
  margin: 30px 0px;
}

.sidebar .comp-block .comp-title {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}

.sidebar .comp-block .comp-numbers {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 10px;
}

.sidebar .comp-block .comp-numbers i {
  font-size: 1.3em;
}

.sidebar .comp-block .comp-numbers .comp-number {
  margin: 0px;
  font-size: 33px;
  font-weight: 400;
  line-height: 1;
}

.sidebar .comp-block .comp-numbers .comp-number-label {
  font-size: 17px;
  font-weight: 300;
  margin: 0px;
}


.sidebar .comp-block .comp-text {
  font-size: 1.7em;
  font-weight: 400;
  margin: 0px;
}

.sidebar .sidebar-logo {
  width: 80%;
  margin-top: -15px;
  display: block;
  margin: auto;
}

.sidebar .disclaimer {
  margin-top: 20px;
  font-weight: 400;
  color: grey;
  text-align: center;
  font-size: 0.9em;
}

.action-btn {
  border: none;
  display: block;
  margin: auto;
  font-weight: 600;
  width: 300px;
  padding: 10px;
  margin-top: 30px;
  font-size: 18px;
}

.report-section-title {
  font-size: 16px;
  color: #515151;
  font-weight: 500;
  text-transform: uppercase;
}

.summary-block-wrapper {
  padding: 10px;
}

.summary-block {
  padding: 20px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-block .summary-block-pic {
  width: 60px;
  margin-bottom: 25px;
}

.summary-block .summary-block-title {
  color: #767676;
}

.summary-block .summary-block-value {
  font-size: 1.7em;
  font-weight: 600;
}

.report-summary-incentive {
  font-size: 16px;
  color: #515151;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 400;
}

.report-summary-incentive a {
  font-weight: 600;
  text-decoration: underline;
}


.shadowed {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
  box-shadow: 0px 0px 20px 0px rgba(118,118,118,0.2);
}